<template>
  <div id="app">
    <main>
      <router-view />
    </main>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() { },
};
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  main {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
}


@media (max-width: 768px) {
  .isMask {
    overflow: hidden !important;
  }

  .isMask_header {
    background: rgba(0, 0, 0, 0) !important;
    border-bottom-color: rgba(0, 0, 0, 0) !important;
  }
}
</style>
