import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipBoard from 'vue-clipboard2'
import '@/utils/rem.js'
import utils from '@/utils/index'

// 重置样式
import '@/assets/css/idangerous.swiper2.7.6.css'
import '@/assets/css/styles.css'
import '@/assets/css/iconfont.css'
import '@/assets/css/animate.css'
import '@/assets/css/animate.min.css'

// 导入组件
Vue.config.productionTip = false
Vue.prototype.$utils = utils


// img元素上使用v-lazy="src";<img v-lazy="showImage"/>

Vue.use(VueClipBoard)
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

