import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    settingInfo: {},
    userInfo: {},
  },
  getters: {
  },
  mutations: {
    SET_ENCODE (state, payload) {
      state.token = payload
    },
    SET_SETTING (state, payload) {
      state.settingInfo = payload
    },
    SET_USERINFO (state, payload) {
      state.userInfo = payload
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: "encryptionStore",
    }),
  ], //vuex持久化
})
