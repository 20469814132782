import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import("@/view/layout.vue"),
    redirect: '/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import("@/view/index/index.vue"), //首页
      }, {
        path: 'web',
        name: 'web',
        component: () => import("@/view/web/web.vue"), //网站建设
      }, {
        path: 'app',
        name: 'app',
        component: () => import("@/view/app/app.vue"), //APP开发
      }, {
        path: 'wechat',
        name: 'wechat',
        component: () => import("@/view/wechat/wechat.vue"), //APP开发
      }, {
        path: 'soft',
        name: 'soft',
        component: () => import("@/view/soft/soft.vue"), //APP开发
      }, {
        path: 'case',
        name: 'case',
        component: () => import("@/view/case/case.vue"), //APP开发
      }, {
        path: 'product',
        name: 'product',
        component: () => import("@/view/product/product.vue"), //APP开发
      }, {
        path: 'news',
        name: 'news',
        component: () => import("@/view/news/news.vue"), //APP开发
      }, {
        path: 'about',
        name: 'about',
        component: () => import("@/view/about/about.vue"), //APP开发
      }, {
        path: 'contact',
        name: 'contact',
        component: () => import("@/view/contact/contact.vue"), //APP开发
      }, {
        path: 'message',
        name: 'message',
        component: () => import("@/view/message/message.vue"), //APP开发
      }, {
        path: 'release',
        name: 'release',
        component: () => import("@/view/message/release.vue"), //APP开发
      }, {
        path: 'newsDetail/:id?',
        name: 'newsDetail',
        component: () => import("@/view/news/newsDetail.vue"), //APP开发
      },
      {
        path: 'productDetail/:id?',
        name: 'productDetail',
        component: () => import("@/view/product/productDetail.vue"), //APP开发
      }, {
        path: 'messageDetail/:id?',
        name: 'messageDetail',
        component: () => import("@/view/message/messageDetail.vue"), //APP开发
      },
      {
        path: 'caseDetail/:id?',
        name: 'caseDetail',
        component: () => import("@/view/case/caseDetail.vue"), //APP开发
      },
      // {
      //   path: 'notice/:id?/:title?',
      //   name: 'notice',
      //   component: () => import("@/view/index/noticeInfo.vue"), // 首页公告
      // }
      {
        // 登录
        path: "login",
        name: "login",
        component: () => import("@/view/user/login.vue")
      },
      {
        // 注册
        path: "register",
        name: "register",
        component: () => import("@/view/user/register.vue")
      },
    ],
  },

]
// hash模式
const router = new VueRouter({
  routes
})

router.afterEach(() => {
  window.scrollTo(0, 0);
})
export default router
